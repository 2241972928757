var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-container',{staticClass:"py-md-4"},[_c('div',{class:[_vm.style['register_wrapper'], 'my-md-5 my-4 p-3']},[_c('b-row',{class:[{'text-white' : _vm.userLogged == null}, 'justify-content-lg-center']},[_c('b-col',{attrs:{"xl":"10","lg":"11"}},[_c('div',{staticClass:"register-wrapper-header text-center pt-5 mb-5"},[_c('h1',{class:[
                _vm.style['title'],
                'font-weight-bold mb-4' ]},[_vm._v(" Contact ")]),_c('div',{staticClass:"h5 d-flex align-items-center justify-content-center mb-3"},[_vm._v(" There are two way to contact us ")])]),_c('div',{staticClass:"register-wrapper-form"},[_c('div',{staticClass:"register-form"},[_c('form',[_c('b-row',{staticClass:"pb-1 mb-5 border-bottom"},[_c('b-col',{attrs:{"md":"4","xl":"3"}},[_c('div',{staticClass:"d-flex h4 mb-4 font-weight-bold"},[_c('span',{staticClass:"text-primary mr-3"},[_vm._v("01 /")]),_c('span',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Call us")])])])]),_c('b-col',{attrs:{"xl":"8"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"xl":"12"}},[_c('h5',[_c('span',[_vm._v(_vm._s(_vm.phone_number))]),_vm._v(" / "),_c('span',{staticClass:"lead"},[_vm._v(" we're available between "+_vm._s(_vm.contact_us_from)+" and "+_vm._s(_vm.contact_us_to)+" ")])])])],1)],1)],1),_c('b-row',{staticClass:"pb-1 mb-1"},[_c('b-col',{attrs:{"md":"4","xl":"3"}},[_c('div',{staticClass:"d-flex h4 mb-4 font-weight-bold"},[_c('span',{staticClass:"text-primary mr-3"},[_vm._v("02 /")]),_c('span',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Write")]),_c('span',[_vm._v("an email")])])])]),_c('b-col',{attrs:{"xl":"8"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"xl":"12"}},[_c('b-row',[_c('b-col',{staticClass:"font-open-sans font-weight-600",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label-for":"name","label":"Your name"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                                  required: true,
                                  min: 3,
                                }),expression:"{\n                                  required: true,\n                                  min: 3,\n                                }"}],attrs:{"id":"name","state":_vm.validateState(
                                    'name'
                                  ),"aria-describedby":"input-1-live-name","data-vv-as":"Name","name":"name","type":"text","maxlength":"50"},model:{value:(
                                  _vm.name
                                ),callback:function ($$v) {
                                  _vm.name
                                =$$v},expression:"\n                                  name\n                                "}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-name"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first( "name" ))+" ")])],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label-for":"email","label":"Your email"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                                  required: true,
                                  email: true,
                                }),expression:"{\n                                  required: true,\n                                  email: true,\n                                }"}],attrs:{"id":"email","state":_vm.validateState(
                                    'email'
                                  ),"aria-describedby":"input-1-live-email","data-vv-as":"Email","name":"email","type":"email"},model:{value:(
                                  _vm.email
                                ),callback:function ($$v) {
                                  _vm.email
                                =$$v},expression:"\n                                  email\n                                "}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-email"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first( "email" ))+" ")])],1)],1)],1),_c('b-form-group',{staticClass:"font-open-sans font-weight-600",attrs:{"label-for":"message","label":"Message"}},[_c('b-form-textarea',{directives:[{name:"validate",rawName:"v-validate",value:({
                              required: true,
                            }),expression:"{\n                              required: true,\n                            }"}],staticClass:"no-resize",attrs:{"id":"message","state":_vm.validateState(
                                'message'
                              ),"aria-describedby":"input-1-live-message","data-vv-as":"message","name":"message","placeholder":"","rows":"4","required":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-message"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first( "message" ))+" ")])],1)],1)],1)],1)],1),_c('b-row',{staticClass:"pb-1 mb-1"},[_c('b-col',{attrs:{"xl":"11"}},[_c('div',{staticClass:"text-right mb-0 mr-0"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.isLoading,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{ref:"button",staticClass:"px-4 mr-0",attrs:{"disabled":_vm.isLoading,"variant":"golden","pill":""},on:{"click":_vm.handleNext}},[_c('span',{staticClass:"px-4"},[_vm._v("Send")])])],1)],1)])],1)],1)])])]),_c('b-row')],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }