<template>
  <section>
    <b-container class="py-md-4">
      <div :class="[style['register_wrapper'], 'my-md-5 my-4 p-3']">
        <b-row :class="[{'text-white' : userLogged == null}, 'justify-content-lg-center']">
          <b-col
            xl="10"
            lg="11"
          >
            <div
              class="register-wrapper-header text-center pt-5 mb-5"
            >
              <h1
                :class="[
                  style['title'],
                  'font-weight-bold mb-4',
                ]"
              >
                Contact
              </h1>
              <div
                class="h5 d-flex align-items-center justify-content-center mb-3"
              >
                There are two way to contact us
              </div>
            </div>
            <div class="register-wrapper-form">
              <div class="register-form">
                <form>
                  <b-row class="pb-1 mb-5 border-bottom">
                    <b-col
                      md="4"
                      xl="3"
                    >
                      <div
                        class="d-flex h4 mb-4 font-weight-bold"
                      >
                        <span
                          class="text-primary mr-3"
                        >01 /</span>
                        <span
                          class="d-flex flex-column"
                        >
                          <span>Call us</span>
                        </span>
                      </div>
                    </b-col>
                    <b-col xl="8">
                      <b-row
                        class="justify-content-center"
                      >
                        <b-col xl="12">
                          <h5> <span>{{ phone_number }}</span> / <span class="lead"> we're available between {{ contact_us_from }} and {{ contact_us_to }} </span> </h5>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row class="pb-1 mb-1">
                    <b-col
                      md="4"
                      xl="3"
                    >
                      <div
                        class="d-flex h4 mb-4 font-weight-bold"
                      >
                        <span
                          class="text-primary mr-3"
                        >02 /</span>
                        <span
                          class="d-flex flex-column"
                        >
                          <span>Write</span>
                          <span>an email</span>
                        </span>
                      </div>
                    </b-col>
                    <b-col xl="8">
                      <b-row
                        class="justify-content-center"
                      >
                        <b-col xl="12">
                          <b-row>
                            <b-col
                              lg="6"
                              class="font-open-sans font-weight-600"
                            >
                              <b-form-group
                                label-for="name"
                                label="Your name"
                              >
                                <b-form-input
                                  id="name"
                                  v-model="
                                    name
                                  "
                                  v-validate="{
                                    required: true,
                                    min: 3,
                                  }"
                                  :state="
                                    validateState(
                                      'name'
                                    )
                                  "
                                  aria-describedby="input-1-live-name"
                                  data-vv-as="Name"
                                  name="name"
                                  type="text"
                                  maxlength="50"
                                />
                                <b-form-invalid-feedback
                                  id="input-1-live-name"
                                >
                                  {{
                                    veeErrors.first(
                                      "name"
                                    )
                                  }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </b-col>
                            <b-col lg="6">
                              <b-form-group
                                label-for="email"
                                label="Your email"
                              >
                                <b-form-input
                                  id="email"
                                  v-model="
                                    email
                                  "
                                  v-validate="{
                                    required: true,
                                    email: true,
                                  }"
                                  :state="
                                    validateState(
                                      'email'
                                    )
                                  "
                                  aria-describedby="input-1-live-email"
                                  data-vv-as="Email"
                                  name="email"
                                  type="email"
                                />
                                <b-form-invalid-feedback
                                  id="input-1-live-email"
                                >
                                  {{
                                    veeErrors.first(
                                      "email"
                                    )
                                  }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          
                          <b-form-group
                            label-for="message"
                            label="Message"
                            class="font-open-sans font-weight-600"
                          >
                            <b-form-textarea
                              id="message"
                              v-model="message"
                              v-validate="{
                                required: true,
                              }"
                              :state="
                                validateState(
                                  'message'
                                )
                              "
                              aria-describedby="input-1-live-message"
                              data-vv-as="message"
                              name="message"
                              placeholder=""
                              class="no-resize"
                              rows="4"
                              required
                            />
                            <b-form-invalid-feedback
                              id="input-1-live-message"
                            >
                              {{
                                veeErrors.first(
                                  "message"
                                )
                              }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row class="pb-1 mb-1">
                    <b-col xl="11">
                      <div class="text-right mb-0 mr-0">
                        <b-overlay
                          :show="isLoading"
                          rounded
                          opacity="0.6"
                          spinner-small
                          spinner-variant="primary"
                          class="d-inline-block"
                        >
                          <b-button
                            ref="button"
                            :disabled="isLoading"
                            variant="golden"
                            class="px-4 mr-0"
                            pill
                            @click="handleNext"
                          >
                            <span class="px-4">Send</span>
                          </b-button>
                        </b-overlay>
                      </div>
                    </b-col>
                  </b-row>
                </form>
              </div>
            </div>
          </b-col>
          <b-row />
        </b-row>
      </div>
    </b-container>
  </section>
</template>

<script>
import style from "../styles/registration.module.scss";
import { getAppSettings, sendContactUs } from '../api/contactus';
import { _sendResponse } from '../js/common-function';

export default {
    name: "Register",
    data() {
        return {
            style,
            phone_number: '',
            contact_us_from: '',
            contact_us_to: '',    
            name: '',
            message: '',
            email: '',
            errors: "",
            app_settings: '',
            isLoading: false, // join button click load
            userLogged: localStorage.getItem("fullname"),
        };
    },
    async created (){
        this.app_settings = await getAppSettings();
        this.setNumberTime(this.app_settings);
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        setNumberTime(app_settings_data) {
            this.phone_number = app_settings_data[0].phone_number;
            this.contact_us_from = app_settings_data[1].contact_us_from;
            this.contact_us_to = app_settings_data[2].contact_us_to;
        },
        handleNext() {
            
            this.isLoading = true;
            this.$validator.validateAll().then(result => {
                if (!result) {
                    this.isLoading = false;
                    return;
                }
                
                var formdata = {
                    "name" : this.name,
                    "email" : this.email,
                    "message" : this.message,
                };

                this.sendContactQuery(formdata);
                            
            });
        },
        async sendContactQuery(formdata) {
            var response = await sendContactUs(formdata);
            this.isLoading = false;
            if (response.data.status == false){
                _sendResponse("error",response.data.message);
                return false;
            }
            _sendResponse("success",response.data.message);
            this.$validator.reset();
            this.name = "";
            this.email = "";
            this.message = "";
        }
    },
};
</script>
